import { createSlice } from '@reduxjs/toolkit';
import { archiveCommand, deleteCommand, getAllTypesOfCommand, getCommandById, getCommands, getCommandsWithStatusAndNumber, getLatestCommands, getSearchedCommands, unArchiveCommand, } from './commandThunk';
const initialState = {
    allCommandsWithStatusAndNumber: [],
    allCommands: [],
    allLatestCommands: [],
    allTypesOfCommands: [],
    oneData: undefined,
    loading: false,
    getAllCommandsStatus: 'idle',
    getAllTypesOfCommandStatus: 'idle',
    getLatestCommandsStatus: 'idle',
    deleteCommandStatus: 'idle',
    archiveCommandStatus: 'idle',
    unArchiveCommandStatus: 'idle',
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'command',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCommandsWithStatusAndNumber.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getCommandsWithStatusAndNumber.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.allCommandsWithStatusAndNumber = payload.data;
        })
            .addCase(getCommandsWithStatusAndNumber.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getCommands.pending, (state, actions) => {
            state.getAllCommandsStatus = 'loading';
        })
            .addCase(getCommands.fulfilled, (state, { payload }) => {
            state.getAllCommandsStatus = 'succeeded';
            state.allCommands = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getCommands.rejected, (state, { payload }) => {
            state.getAllCommandsStatus = 'failed';
        })
            .addCase(getSearchedCommands.pending, (state, actions) => {
            state.getAllCommandsStatus = 'loading';
        })
            .addCase(getSearchedCommands.fulfilled, (state, { payload }) => {
            state.getAllCommandsStatus = 'succeeded';
            state.allCommands = payload.data.docs;
            state.total = payload.data.totalDocs;
        })
            .addCase(getSearchedCommands.rejected, (state, { payload }) => {
            state.getAllCommandsStatus = 'failed';
        })
            .addCase(deleteCommand.pending, (state, actions) => {
            state.deleteCommandStatus = 'loading';
        })
            .addCase(deleteCommand.fulfilled, (state, { payload }) => {
            state.deleteCommandStatus = 'succeeded';
        })
            .addCase(deleteCommand.rejected, (state, { payload }) => {
            state.deleteCommandStatus = 'failed';
        })
            .addCase(archiveCommand.pending, (state, actions) => {
            state.archiveCommandStatus = 'loading';
        })
            .addCase(archiveCommand.fulfilled, (state, { payload }) => {
            state.archiveCommandStatus = 'succeeded';
        })
            .addCase(archiveCommand.rejected, (state, { payload }) => {
            state.archiveCommandStatus = 'failed';
        })
            .addCase(unArchiveCommand.pending, (state, actions) => {
            state.unArchiveCommandStatus = 'loading';
        })
            .addCase(unArchiveCommand.fulfilled, (state, { payload }) => {
            state.unArchiveCommandStatus = 'succeeded';
        })
            .addCase(unArchiveCommand.rejected, (state, { payload }) => {
            state.unArchiveCommandStatus = 'failed';
        })
            .addCase(getCommandById.pending, (state) => {
            state.error = false;
            state.loading = true;
        })
            .addCase(getCommandById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.oneData = payload?.data?.data;
        })
            .addCase(getCommandById.rejected, (state) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getLatestCommands.pending, (state) => {
            state.getLatestCommandsStatus = 'loading';
        })
            .addCase(getLatestCommands.fulfilled, (state, { payload }) => {
            state.getLatestCommandsStatus = 'succeeded';
            state.allCommands = payload.data.docs;
            state.total = payload.data.totalDocs;
        })
            .addCase(getLatestCommands.rejected, (state) => {
            state.error = true;
            state.getLatestCommandsStatus = 'failed';
        })
            .addCase(getAllTypesOfCommand.pending, (state) => {
            state.getAllTypesOfCommandStatus = 'loading';
        })
            .addCase(getAllTypesOfCommand.fulfilled, (state, { payload }) => {
            state.getAllTypesOfCommandStatus = 'succeeded';
            state.allTypesOfCommands = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getAllTypesOfCommand.rejected, (state) => {
            state.error = true;
            state.getAllTypesOfCommandStatus = 'failed';
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const {} = slice.actions;
